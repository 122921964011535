/* Agrega estas reglas en tu archivo de estilos CSS */
.custom-calendar .fc-toolbar-title {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.custom-calendar .fc-header-toolbar {
  flex-wrap: wrap;
  justify-content: center;
}

.custom-calendar .fc-toolbar-chunk {
  display: block;
  margin: 0 10px;
}

.badge-ext {
  background-color: #8a00db !important;
  color: #fff;
}

.filter{
  font-size: 16px;
  font-weight: bold;
}

.dropdown-container{
  z-index: 20 !important;
  height: 50px !important;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .fc .fc-toolbar-title {
    font-size: 1.2em;
    margin: 0px;
  }
  .clean-btn{
    margin-top: 10px;
  }

  .btn-mob {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .cont-btn{
    width: 100%;
  }
}
