.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.container-fluid {
    margin-top: 2.53rem;
}

.form-control:not(textarea) {
    height: 3.125rem !important;
}

.form-control.clear.sm {
    height: auto !important;
    padding: 0;
}

.react-datepicker-wrapper {
    display: block;
    padding: 0;
    border: 0;
}

.link-add {
    color: white !important;
}

/* .table-pr {
  padding-right: 10px !important;
}

.table-lr {
  padding-left: 10px !important;
} */

.SingleDatePicker {
    display: block;
}

.SingleDatePickerInput {
    display: block;
    width: 100%;
    height: 3.125rem;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}


.DateRangePickerInput .DateInput {
    width: 35%;
}

.DateInput {
    width: 100%;
}

.DateInput_input {
    padding: 0;
}

.DateRangePickerInput {
    display: block;
    width: 100%;
    height: 3.125rem;
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.DateRangePickerInput_arrow {
    padding-right: 9px;
}

.DateRangePickerInput_clearDates {
    margin: 0;
}

.DateInput_input__focused {
    border-bottom: none;
}

.table {
    margin-bottom: 0rem !important;
}

.table thead th {
    border-bottom: 1px solid #dee2e6 !important;
}

.table-late,
.table-late > td,
.table-late > th {
    background-color: #4e47c5;
}

.badge-late {
    color: #fff;
    background-color: #4e47c5;
}

.menu {
    border-radius: 3px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    background: inherit;
    padding: 2px 0;
    font-size: 90%;
    position: absolute;
    overflow: auto;
    max-height: 220px;
    width: 100%;
    z-index: 1;
}

.item {
    padding: 3px;
}

.item-highlighted {
    background-color: lightgray;
}

.form {
    width: 100%;
    padding: 15px;
    margin: auto;
    margin-top: 100px;
}

.form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
    margin: auto;
    margin-top: 100px;
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

.form-label-group input:not(:placeholder-shown),
.form-label-group textarea:not(:placeholder-shown),
.form-label-group select:not(:placeholder-shown)
{
    padding-top: 1.25rem;
    padding-bottom: .25rem;
}

.form-label-group .select-autocomplete [class*=control]:not(:has(#react-select-2-placeholder))
{
    padding-top: 1rem;
    padding-bottom: .25rem;
}

.form-label-group .select-autocomplete [class*=ValueContainer]{
    padding: 2px 12px;
}

.form-label-group > input,
.form-label-group > select,
.form-label-group > textarea,
.form-label-group > label{
    height: 3.125rem;
    padding: .9rem;
}

.form-label-group .react-datepicker-wrapper input{
    padding-left: 14px;
}

.form-label-group > .select-autocomplete [class*=control]{
    height: 3.125rem;
}

.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group select:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:placeholder-shown) ~ label,
.form-label-group .react-datepicker-wrapper:not(:has(input:placeholder-shown)) ~ label
{
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
}

.form-label-group .select-autocomplete:not(:has(div#react-select-2-placeholder)) ~ label
{
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
}

.form-label-group > input[error],
.form-label-group > select[error],
.form-label-group > textarea[error]
{
    border-color: red;
}

.form-label-group > label {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
}

.sidebar {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
    position: sticky;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.sidebar .nav-link.active {
    color: #007bff;
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

.searchBox {
    height: 2.2rem;
}

.card {
    min-width: 380px !important;
}

.metrics .card {
    min-width: auto !important;
}

.pro-sidebar {
    min-width: 197px;
    width: 197px;
    position: fixed;
}

main {
    padding-top: 23px;
}


.pro-sidebar > .pro-sidebar-inner {
    background: #343a40;
}

.pro-sidebar .pro-menu.shaped {
    padding-bottom: 40px;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background: #343a40;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
    padding-bottom: 30px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content .active {
    color: #007bff;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 8px 2px 8px 2px;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background: #007bff !important;
    color: #fff !important;
}

#notCount {
    font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: -10px;
}

.tooltip-inner {
    max-width: 500px !important;
}

.tooltip[x-placement^="left"],
.show[x-placement^="left"]:not(.tooltip) {
    margin-right: 5px;
    top: 100px !important;
}

.chip {
    white-space: normal !important;
    word-wrap: break-word;
}

.dataTable tbody tr td a i {
    margin-left: 2px;
    padding: 6px !important;
}

.border-left-primary {
    border-left: .25rem solid #4e73df !important
}

.border-bottom-primary {
    border-bottom: .25rem solid #4e73df !important
}

.border-left-secondary {
    border-left: .25rem solid #858796 !important
}

.border-bottom-secondary {
    border-bottom: .25rem solid #858796 !important
}

.border-left-success {
    border-left: .25rem solid #1cc88a !important
}

.border-bottom-success {
    border-bottom: .25rem solid #1cc88a !important
}

.border-left-info {
    border-left: .25rem solid #36b9cc !important
}

.border-bottom-info {
    border-bottom: .25rem solid #36b9cc !important
}

.border-left-warning {
    border-left: .25rem solid #f6c23e !important
}

.border-bottom-warning {
    border-bottom: .25rem solid #f6c23e !important
}

.border-left-danger {
    border-left: .25rem solid #e74a3b !important
}

.border-bottom-danger {
    border-bottom: .25rem solid #e74a3b !important
}

.border-left-light {
    border-left: .25rem solid #f8f9fc !important
}

.border-bottom-light {
    border-bottom: .25rem solid #f8f9fc !important
}

.border-left-dark {
    border-left: .25rem solid #5a5c69 !important
}

.border-bottom-dark {
    border-bottom: .25rem solid #5a5c69 !important
}

.border-left-primary {
    border-left: .25rem solid #4e47c5 !important
}

.border-bottom-primary {
    border-bottom: .25rem solid #4e47c5 !important
}

#boxes-grid table tr:has(a.badge.missing) {
    background-color: #f5c6cb;
}

table select.form-control {
    width: auto;
}

.Mui-TableHeadCell-Content-Actions {
    margin-left: auto;
}

.badge-home-office {
    background-color: #57D56C;
    color: #fff;
}

.badge-in-office {
    background-color: #0175FA;
    color: #fff;
}

.badge-vacation {
    background-color: #FB476C;
    color: #fff;
}

.badge-fuera{
    background-color: #DBD631;
    color: #fff;
}

.badge-diligencia{
    background-color: #8A00DB !important;
    color: #fff;
}

.badge-guard {
    background-color: #FFA800;
    color: #fff;
}

.sign-box{
    border: 1px solid #444;
    background-color: #ccc;
    height: 90px;
    margin: 1rem auto 0 auto;
    max-width: 550px;
}

.font-smaller{
    font-size: 0.6rem;
    line-height: 10px;
}
